import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'productTypeLbl'
})
export class ProductTypeLblPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    let str = value;
    switch (value) {
      case "pommes":
        str = "Varieties des pommes"
        break;
      case "autre":
        str = "Autres produits"
        break;
      case "boissons":
        str = "Boissons"
        break;
      case "fruits":
        str = "Autres fruits"
        break;
      case "légumes":
        str = "Légumes"
        break;
      default:
        break;
      }
    return str;
  }

}
