<div class="video-container">

  <video autoplay muted controls id="lesvergersdubedou" class="video" (ended)='exitVideo()'>
    <source src="/assets/intro.mp4" type="video/mp4" />
    <source src="/assets/intro.webm" type="video/webm" />
  </video>
  
  <div class="content">
    <!-- <h2>Les vergers du Bedou</h2>
    <p>Vente des pommes</p> -->
    <!-- Use a button to pause/play the video with JavaScript -->
    <div class="btns-container">
      <button mat-stroked-button color='warn' class="custom-btn" (click)="exitVideo()">
        Sortir
      </button>
      <button mat-stroked-button color='accent' class="custom-btn btn-space">
        <a href="https://www.youtube.com/watch?v=2ocT5YGC6xI" target="_blank">
          Regarder la vidéo
        </a>
      </button>
    </div>
    <div class='actions'>
      <mat-checkbox class="check"
      (change)="setVideoSettings($event.checked)"
        >Ne pas afficher cette vidéo la prochaine fois.</mat-checkbox
      >
    </div>
  </div>
</div>
