import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { AngularFirestore } from '@angular/fire/firestore';
import { Iinfos } from 'app/models/infos';

@Injectable()

export class MainInfosService {
    infoSubject = new Subject<Iinfos>();
    infoObs: Observable<Iinfos>;

    constructor(private http: HttpClient, private firestore: AngularFirestore) {
        this.infoObs = this.infoSubject.asObservable();
        this.requestData();
    }

    requestData() {
        return this.firestore.collection<Iinfos>("infos")
        .snapshotChanges().subscribe(
            (res) => {
              const aInfo = res.map(e => {
                return  { key: e.payload.doc.id,
                  ...e.payload.doc.data() as Iinfos };
              });
              if (aInfo.length > 0) {
                  this.infoSubject.next(aInfo[0]);
              }
            }
        );
    }

    create(info: Iinfos){
        const subjectResult = new Subject<boolean>();
        this.firestore.collection('infos').add(info)
            .then((res) => {
                this.requestData();
                subjectResult.next(true);
            }).catch(
                (err) => {
                    subjectResult.next(false);
                }
            )
        return subjectResult.asObservable();
    }

    update(info: Iinfos) {
        const subjectResult = new Subject<boolean>();
        this.firestore.collection('infos').doc(info.key).update(info)
            .then((res) => {
                this.requestData();
                subjectResult.next(true);
            }).catch(
                (err) => {
                    subjectResult.next(false);
                }
            )
        return subjectResult.asObservable();
    }

    // delete(info: Iinfos) {
    //     const subjectResult = new Subject<boolean>();
    //     this.firestore.collection('infos').doc(info.key).delete()
    //         .then((res) => {
    //             this.requestData();
    //             subjectResult.next(true);
    //         }).catch(
    //             (err) => {
    //                 subjectResult.next(false);
    //             }
    //         )
    //     return subjectResult.asObservable();
    // }

}