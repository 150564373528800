import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { Inews } from 'app/models/news';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable()

export class NewsService {
    aNewsSubject = new Subject<Inews[]>();
    aNewsObs: Observable<Inews[]>;

    constructor(private http: HttpClient, private firestore: AngularFirestore) {
        this.aNewsObs = this.aNewsSubject.asObservable();
        this.requestData();
    }

    requestData() {
        return this.firestore.collection<Inews>("news")
        .snapshotChanges().subscribe(
            (res) => {
              const aNews = res.map(e => {
                return  { key: e.payload.doc.id,
                  ...e.payload.doc.data() as Inews };
              });
              this.aNewsSubject.next(aNews);
            }
        );
    }

    create(note: Inews){
        const subjectResult = new Subject<boolean>();
        this.firestore.collection('news').add(note)
            .then((res) => {
                this.requestData();
                subjectResult.next(true);
            }).catch(
                (err) => {
                    subjectResult.next(false);
                }
            )
        return subjectResult.asObservable();
    }

    update(note: Inews) {
        const subjectResult = new Subject<boolean>();
        this.firestore.collection('news').doc(note.key).update(note)
            .then((res) => {
                this.requestData();
                subjectResult.next(true);
            }).catch(
                (err) => {
                    subjectResult.next(false);
                }
            )
        return subjectResult.asObservable();
    }

    delete(note: Inews) {
        const subjectResult = new Subject<boolean>();
        this.firestore.collection('news').doc(note.key).delete()
            .then((res) => {
                this.requestData();
                subjectResult.next(true);
            }).catch(
                (err) => {
                    subjectResult.next(false);
                }
            )
        return subjectResult.asObservable();
    }

}