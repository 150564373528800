import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { AngularFirestore } from '@angular/fire/firestore';
import { IMessage } from 'app/models/message';
import { map } from 'rxjs/operators';

@Injectable()
export class DataService {
    aMessagesSubject = new Subject<IMessage[]>();
    aMessagesObs: Observable<IMessage[]>;
    private emailUrl = '/assets/email.php';

    constructor(private http: HttpClient, private firestore: AngularFirestore) {
        this.aMessagesObs = this.aMessagesSubject.asObservable();
        this.requestData();
    }

    sendEmail(message: IMessage): Observable<IMessage> | any {
        return this.http.post(this.emailUrl, message)
        .pipe(
            map(
                (response) => {
                    console.log('Sending email was successfull', response);
                    return response;
                }, 
                (error) => {
                    console.log('Sending email got error', error.message);
                    return Observable.throw(error.message);
                }
            )
        );
    }

    requestData() {
        return this.firestore.collection<IMessage>("messages",
            ref => ref.orderBy('timestamp', 'desc')
        ).snapshotChanges()
        .subscribe(
            (res) => {
              const aMsgs = res.map(e => {
                return  { key: e.payload.doc.id,
                  ...e.payload.doc.data() as IMessage };
              });
              this.aMessagesSubject.next(aMsgs);
            }
        );
    }

    create(message: IMessage){
        const subjectResult = new Subject<boolean>();
        this.firestore.collection('messages').add(message)
            .then((res) => {
                this.requestData();
                subjectResult.next(true);
            }).catch(
                (err) => {
                    subjectResult.next(false);
                }
            )
        return subjectResult.asObservable();
    }

    
    update(note: IMessage) {
        const subjectResult = new Subject<boolean>();
        this.firestore.collection('messages').doc(note.key).update(note)
            .then((res) => {
                this.requestData();
                subjectResult.next(true);
            }).catch(
                (err) => {
                    subjectResult.next(false);
                }
            )
        return subjectResult.asObservable();
    }

    delete(note: IMessage) {
        const subjectResult = new Subject<boolean>();
        this.firestore.collection('messages').doc(note.key).delete()
            .then((res) => {
                this.requestData();
                subjectResult.next(true);
            }).catch(
                (err) => {
                    subjectResult.next(false);
                }
            )
        return subjectResult.asObservable();
    }

}