import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  a_slides = [
    "https://via.placeholder.com/1920x480.png",
    "assets/certifications.png",
    "assets/img/products/chantecler.jpg",
    "assets/img/products/gala.jpg",
  ];

  constructor() { }

  ngOnInit() {

  }

}
