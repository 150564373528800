
<div fxFlex fxLayout.lt-sm="column"
    *ngIf="ready; else loading">
    <div class="filters">
        <div class="search">
            <mat-icon class="mat-18">search</mat-icon>
            <mat-form-field class="search-form">
                <input matInput #searchInput placeholder="Chercher un produit" type="text" [(ngModel)]="search" (input)="onSearchInput(searchInput.value)">
            </mat-form-field>
        </div>
        <div class="type-btns">
            <div *ngFor="let type of types" class="type-btn">
                <button mat-raised-button color="primary" (click)="onTypeSelected(type)" 
                    [ngClass]="{'selected': selectedType === type}">
                    {{type | titlecase}}
                </button>
            </div>
        </div>
        <div class="search">
            <mat-slide-toggle 
                labelPosition="before"
                (change)="onDisponbleChanged($event)"
            >
                Seulement disponiles
            </mat-slide-toggle>
        </div>
    </div>
    <div class="gallery" fxLayout.xs="column" fxLayoutGap="0.5%" fxLayoutAlign="center">
        <mat-card class="card-picture" *ngFor="let o_product of filteredItems">
            <mat-card-title>
                {{o_product.s_name}}
            </mat-card-title>
            <img class="card-img" [src]="o_product?.s_img" >
            <mat-card-content>
                <p><b>{{o_product?.s_info}}</b></p>
            </mat-card-content>
        </mat-card>
    </div>
</div>
<ng-template #loading >
    <div class="center-content loading">
        <mat-spinner></mat-spinner>
    </div>
</ng-template>