import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { AngularFirestore } from '@angular/fire/firestore';
import { Idays } from 'app/models/days';

@Injectable()

export class TimetableService {
    aDaysSubject = new Subject<Idays[]>();
    aDaysObs: Observable<Idays[]>;

    constructor(private http: HttpClient, private firestore: AngularFirestore) {
        this.aDaysObs = this.aDaysSubject.asObservable();
        this.requestData();
    }

    requestData() {
        return this.firestore.collection<Idays>("timetable", ref => ref.orderBy('s_id', 'asc'))
        .snapshotChanges().subscribe(
            (res) => {
              const aDays = res.map(e => {
                return  { key: e.payload.doc.id,
                  ...e.payload.doc.data() as Idays };
              });
              this.aDaysSubject.next(aDays);
            }
        );
    }

    create(day: Idays){
        const subjectResult = new Subject<boolean>();
        this.firestore.collection('timetable').add(day)
            .then((res) => {
                this.requestData();
                subjectResult.next(true);
            }).catch(
                (err) => {
                    subjectResult.next(false);
                }
            )
        return subjectResult.asObservable();
    }

    update(day: Idays) {
        const subjectResult = new Subject<boolean>();
        this.firestore.collection('timetable').doc(day.key).update(day)
            .then((res) => {
                this.requestData();
                subjectResult.next(true);
            }).catch(
                (err) => {
                    subjectResult.next(false);
                }
            )
        return subjectResult.asObservable();
    }

    delete(day: Idays) {
        const subjectResult = new Subject<boolean>();
        this.firestore.collection('timetable').doc(day.key).delete()
            .then((res) => {
                this.requestData();
                subjectResult.next(true);
            }).catch(
                (err) => {
                    subjectResult.next(false);
                }
            )
        return subjectResult.asObservable();
    }

}