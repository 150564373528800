<div
  fxFlex
  fxLayout="column"
  fxFlex
  fxLayout.lt-sm="column"
  *ngIf="ready; else loading"
>
  <div fxFlex class="mt-1">
    <mat-accordion>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title> Petite vue du ciel... </mat-panel-title>
          <mat-panel-description>
            Ouvrir
          </mat-panel-description>
        </mat-expansion-panel-header>
        <iframe
          width="840"
          height="472"
          src="https://www.youtube.com/embed/2ocT5YGC6xI"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
  <div>
    <div class="gallery-container">
      <div class="list-container">
        <div class="list-photos">
          <div
            *ngFor="let photo of aImages"
            class="photo"
            (click)="selectPhoto(photo)"
          >
            <img [src]="photo.url" alt="" />
          </div>
        </div>
      </div>
      <div
        *ngIf="bigImage !== null"
        [@showBigImageAnimation]
        class="main-image-container"
      >
        <div class="main-photo">
          <mat-icon class="close-icon" (click)="closeImg()">close</mat-icon>
          <img [src]="bigImage.url" alt="" />
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #loading>
  <div class="center-content loading">
    <mat-spinner></mat-spinner>
  </div>
</ng-template>
