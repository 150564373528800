import { Component, OnDestroy } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Idays } from 'app/models/days';
import { Inews } from 'app/models/news';
import { Iprices } from 'app/models/prices';
import { NewsService } from 'app/services/news.service';
import { PricesService } from 'app/services/prices.service';
import { TimetableService } from 'app/services/timetable.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-edit-main-infos',
  templateUrl: './edit-main-infos.component.html',
  styleUrls: ['./edit-main-infos.component.scss', './../modify.component.scss'],
  providers: [
    NewsService,
    PricesService,
    TimetableService,
  ]
})
export class EditMainInfosComponent implements OnDestroy {
  private unsubscribe = new Subject<void>();
  
  a_news: Inews[] = [];
  touchedNew: boolean = false;
  
  newNewDesc: string;

  touchedPrice: boolean = false;
  newPrice: Iprices = null;

  touchedTimetable: boolean = false;
  newDay: Idays = null;


  constructor(
    private _snackBar: MatSnackBar,
    public newsService: NewsService,
    public pricesService: PricesService,
    public timetableService: TimetableService,
  ) { }
  
  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  
  // news

  inputNew() {
    this.touchedNew = this.newNewDesc !== '' && this.newNewDesc !== undefined;
  }

  changeVisibility(selNew: Inews) {
    selNew.b_show = !selNew.b_show;
    this.newsService.update(selNew)
    .pipe(takeUntil(this.unsubscribe))  
    .subscribe(() => {
        this._snackBar.open("La note a été modifié", 'Ok', {
          duration: 1500});
    });
  }

  deleteNew(selNew: Inews) {
    this.newsService.delete(selNew)
    .pipe(takeUntil(this.unsubscribe))  
    .subscribe(
        () => {
          this._snackBar.open("La note a été effacé", 'Ok', {
            duration: 1500});
        }, 
        (error) => {
          this._snackBar.open("Une erreur est survenue", 'Ok', {
            duration: 1500});
        }
      );
  }

  saveNew() {
    if (this.touchedNew) {
      const newNote: Inews = {
        s_description: this.newNewDesc,
        b_show: true
      }
      this.newsService.create(newNote)
      .pipe(takeUntil(this.unsubscribe))  
      .subscribe(
          () => {
            this.newNewDesc = '';
            this.touchedNew = false;
            this._snackBar.open("Bien sauvergardé", 'Ok', {
              duration: 1500});
          });
    }
  }

  // prices

  priceInEdition(price: Iprices){
    return (this.newPrice != null && 
        price.key === this.newPrice.key
      );
  }

  editPrice(price: Iprices) {
    this.touchedPrice = true;
    this.newPrice = {
      key: price.key,
      s_title: price.s_title,
      types: price.types,
    }
  }

  savePrices(actionDone: {action: string}) {
    if (this.touchedPrice) {
      this.pricesService.update(this.newPrice)
      .pipe(takeUntil(this.unsubscribe))  
      .subscribe(
          () => {
            this.newPrice = null;
            this.touchedPrice = false;
            this._snackBar.open("Bien sauvergardé", 'Ok', {
              duration: 1500});
          });
    }
  }

  // timetable

  dayInEdition(day: Idays) {
    return (this.newDay != null
      && this.newDay.s_id === day.s_id);
  }

  editDay(day) {
    this.newDay = day;
    this.touchedTimetable = true;
  }

  saveTimetable(actionDone: {action: string}) {
    if (this.newDay != null) {
      this.timetableService.update(this.newDay)
      .pipe(takeUntil(this.unsubscribe))  
      .subscribe(
          () => {
            this.newDay = null;
            this.touchedTimetable = false;
            this._snackBar.open("Bien sauvergardé", 'Ok', {
              duration: 1500});
          });
    }
  }

}
