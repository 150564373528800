import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-modif-card',
  templateUrl: './modif-card.component.html',
  styleUrls: ['./modif-card.component.scss']
})
export class ModifCardComponent implements OnInit {
  @Input() header: string;
  @Input() touched: boolean;
  @Output('actionSave') actionDone = new EventEmitter<{action: string}>();

  constructor() { }

  ngOnInit() {
  }

  outputSave() {
    this.actionDone.emit({action:'Done'});
  }

}
