import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Iproducts } from 'app/models/products';
import { ProductsService } from 'app/services/products.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ProductDialogComponent } from '../product-dialog/product-dialog.component';
import { NewProductDialog } from './new-product/new-product.component';

@Component({
  selector: 'app-edit-products',
  templateUrl: './edit-products.component.html',
  styleUrls: ['./edit-products.component.scss', './../modify.component.scss'],
  providers: [
    ProductsService
  ]
})
export class EditProductsComponent implements OnInit, OnDestroy  {
  private unsubscribe = new Subject<void>();
  
  touchedProducts: boolean = false;
  newProduct: Iproducts = null;
  newProductTypes = new FormControl();
  availableTypes = [];
  productsImgUrls: string[] = [];
  totalProducts = 0;

  constructor(
    private _snackBar: MatSnackBar, 
    private cd: ChangeDetectorRef,
    public dialog: MatDialog,
    public productsService: ProductsService
  ) { }

  ngOnInit() {
    const types = new Set([]);
    this.productsService.aProductsObs
    .pipe(takeUntil(this.unsubscribe))
    .subscribe(
      (productList: Iproducts[]) => {
        this.totalProducts = productList.length;
        productList.forEach(product => {
          for (const type of product.a_type) {
            types.add(type);
          }
        });
        this.availableTypes = Array.from(types.values());
    });
    this.productsService.aProductsImgsObs
    .pipe(takeUntil(this.unsubscribe))
    .subscribe(
      (aUrls: string[]) => {
        this.productsImgUrls = aUrls;
      }
    )
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  
  // products

  productInEdition(product: Iproducts) {
    return (this.newProduct != null
      && this.newProduct.key === product.key);
  }

  editProduct(product) {
    if(this.touchedProducts) {
      this.touchedProducts = false;
      this.newProduct = null;
    } else {
      this.newProduct = product;
      this.touchedProducts = true;
    }
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(ProductDialogComponent,{
      data: {
        products: this.productsImgUrls
      }
    });

    dialogRef.afterClosed()
    .pipe(takeUntil(this.unsubscribe))
    .subscribe(result => {      
      if (result != null) {
        this.newProduct.s_img = result;
        this.cd.markForCheck();
      }
    });
  }

  saveProduct(actionDone?: {action: string}) {
    if (this.newProduct != null) {
      this.productsService.update(this.newProduct)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        () => {
          this.newProduct = null;
          this.touchedProducts = false;
          this._snackBar.open("Bien sauvergardé", 'Ok', {
            duration: 1500});
        });
    }
  }

  onFileSelected(event) {
    var n = Date.now();
    const file = event.target.files[0];
    this.productsService.saveFile(file);
  }

  addProduct() {
      const dialogRef = this.dialog.open(NewProductDialog, {
        //width: '450px',
        data: {totalProducts: this.totalProducts, types: this.availableTypes}
      });
  
      dialogRef.afterClosed().subscribe(result => {
        if (result != null) {
          this.productsService.create(result)
          .pipe(takeUntil(this.unsubscribe))
          .subscribe(
            () => {
              this._snackBar.open("Bien sauvergardé", 'Ok', {
                duration: 1500});
            });
        }
      });//dialogref
  }
 

}
