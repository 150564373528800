<div fxFlex fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-around start">
    <!-- Products  -------------------------->
    <app-modif-card [header]="'Products'" [touched]='touchedProducts' (actionSave)='saveProduct($event)'>
        <div class="flex-wrap-container">
            <div *ngFor='let product of productsService.aProductsObs | async' class="products-column">
                <div class="container-lines">
                    <div class="subtitle">
                        {{product.s_name}}:
                        <div class="icons">
                            <mat-icon class="mat-18 icon-edit" *ngIf="productInEdition(product)" (click)='saveProduct()'>save</mat-icon>
                            <mat-icon class="mat-18 icon-edit" (click)="editProduct(product)">
                                {{productInEdition(product) ? 'close' : 'edit'}}
                            </mat-icon>
                        </div>
                    </div>                        
                    <div class="line-inside-w-image">
                        <div class="img-left">
                            <img [src]="productInEdition(product) ? newProduct.s_img : product.s_img" alt="">
                            <div *ngIf="productInEdition(product)">
                                <a (click)="openDialog()" class="fake-link">Changer l'image</a>
                            </div>
                        </div>
                        <div class="desc-right">
                            <div *ngIf="productInEdition(product); else displayProduct" class="line-inside container-lines">
                                <mat-slide-toggle labelPosition="before" [(ngModel)]="newProduct.b_show">{{newProduct.b_show ? 'Visible' : 'Caché'}}</mat-slide-toggle>
                                <mat-slide-toggle labelPosition="before" [(ngModel)]="newProduct.b_disponible">{{!newProduct.b_disponible ? 'Non disponible' : 'Disponible'}}</mat-slide-toggle>
                                <mat-form-field class="mr-1 mt-1">
                                    <textarea  matInput placeholder="Description" [(ngModel)]="newProduct.s_info"></textarea>
                                </mat-form-field>
                                <mat-form-field appearance="fill">
                                    <mat-label>Categories</mat-label>
                                    <mat-select [(value)]="newProduct.a_type"  multiple>
                                        <mat-option *ngFor="let type of availableTypes" [value]="type">{{type | productTypeLbl}}</mat-option>
                                    </mat-select>
                                </mat-form-field> 
                            </div>
                            <ng-template #displayProduct>
                                <div class="container-lines">
                                    <span>
                                        Visible: 
                                        <mat-icon class="icon-edit">{{product.b_show ? 'visibility' : 'visibility_off'}}</mat-icon>
                                    </span>
                                    <span class="mr-2">{{product.s_info != null ? product.s_info : "Pas de description"}}</span>
                                    <span class="mr-2">{{product.s_types}}</span>
                                </div>
                            </ng-template>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="extra-actions">
            <div class="newImage">
                <label for="file">Ajouter une nouvelle image: </label>
                <input type="file"
                    id="file"
                    #newImgGallery
                    (change)="onFileSelected($event)"
                    name="image"
                    autocomplete="off"
                />
            </div>
            <div class="btn-add">
                <button mat-raised-button (click)='addProduct()' color='primary'>Ajouter un nouvel produit</button>
            </div> 
        </div>
    </app-modif-card>
    <!-- END PRODUCTS  -------------------------->
</div>