import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import {MaterialModule} from './material.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import {FormsModule} from '@angular/forms';

// Import the libs you need
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAuthModule } from '@angular/fire/auth';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PhotosComponent } from './components/photos/photos.component';
import { ProductsComponent } from './components/products/products.component';
import { MethodComponent } from './components/method/method.component';
import { ContactComponent } from './components/contact/contact.component';
import { HomeComponent } from './components/home/home.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HeaderComponent } from './components/header/header.component';
import { LoginComponent } from './auth/login/login.component';
import { ModifyComponent } from './auth/modify/modify.component';
import { ModifCardComponent } from './lib-components/modif-card/modif-card.component';
import { AuthInterceptorService } from './services/auth-interceptor.service';
import { ProductTypeLblPipe } from './pipes/product-type-lbl.pipe';
import { ProductDialogComponent } from './auth/modify/product-dialog/product-dialog.component';
import { FirestoreDatePipe } from './pipes/firestore-date.pipe';
import { MessagesComponent } from './auth/modify/inbox/inbox.component';
import { EditMainInfosComponent } from './auth/modify/edit-main-infos/edit-main-infos.component';
import { EditProductsComponent } from './auth/modify/edit-products/edit-products.component';
import { EditPhotosComponent } from './auth/modify/edit-photos/edit-photos.component';
import { NewProductDialog } from './auth/modify/edit-products/new-product/new-product.component';
import { IntroVideoComponent } from './components/intro-video/intro-video.component';

const firebaseConfig = {
  apiKey: "AIzaSyCSuJ4Ys0lt0dJvNaJiQ261Ahuiu1AfKP4",
  authDomain: "bedou-ff0d2.firebaseapp.com",
  databaseURL: "https://bedou-ff0d2.firebaseio.com",
  projectId: "bedou-ff0d2",
  storageBucket: "bedou-ff0d2.appspot.com",
  messagingSenderId: "350156400244",
  appId: "1:350156400244:web:5bbf2eed055b8757009321"
};


@NgModule({
  declarations: [
    AppComponent,
    PhotosComponent,
    ProductsComponent,
    MethodComponent,
    ContactComponent,
    HomeComponent,
    HeaderComponent,
    LoginComponent,
    ModifyComponent,
    ModifCardComponent,
    ProductTypeLblPipe,
    ProductDialogComponent,
    FirestoreDatePipe,
    MessagesComponent,
    EditMainInfosComponent,
    EditProductsComponent,
    NewProductDialog,
    EditPhotosComponent,
    IntroVideoComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MaterialModule,
    AppRoutingModule,
    FlexLayoutModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    AngularFireModule.initializeApp(firebaseConfig),
    AngularFirestoreModule, // firestore
    AngularFireAuthModule, // auth
    AngularFireStorageModule, // storage,
  ],
  entryComponents: [ProductDialogComponent, NewProductDialog],
  providers: [{
    provide: HTTP_INTERCEPTORS, 
    useClass: AuthInterceptorService, 
    multi:true}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
