
<h1 mat-dialog-title>Selecciona tu avatar:</h1>
<div mat-dialog-content>

    <div class="flex-grid-layout">
        <div *ngFor='let item of data?.products' class="container-img">
            <img [src]="item" alt="" (click)="selectAvatar(item)" 
            [ngClass]="{'avatar': true, 'selected': isActive(item)}">
        </div>
    </div>

<div mat-dialog-actions>
    <button mat-button (click)="onNoClick()">Fermer</button>
    <button mat-button [mat-dialog-close]="selected" cdkFocusInitial [disabled]="selected === null">Ok</button>
  </div>
  