import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { LoginComponent } from './auth/login/login.component';
import { ProductsComponent } from './components/products/products.component';
import { PhotosComponent } from './components/photos/photos.component';
import { MethodComponent } from './components/method/method.component';
import { ContactComponent } from './components/contact/contact.component';
import { ModifyComponent } from './auth/modify/modify.component';
import { AuthGuard } from './auth/auth.guard';
import { IntroVideoComponent } from './components/intro-video/intro-video.component';


const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'admin', component: LoginComponent },
  { path: 'admin/modify', component: ModifyComponent, canActivate: [AuthGuard] },
  { path: 'products', component: ProductsComponent},
  { path: 'photos', component: PhotosComponent},
  { path: 'method', component: MethodComponent },
  { path: 'contact', component: ContactComponent},
  { path: 'home', component: HomeComponent },
  { path: '**', redirectTo: '/'},
  { path: '', redirectTo: '/home', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
