import { Injectable } from '@angular/core';
import { forkJoin, Observable, Subject } from 'rxjs';
import { AngularFirestore } from '@angular/fire/firestore';
import { Iproducts } from 'app/models/products';
import { AngularFireStorage } from '@angular/fire/storage';
import { finalize } from 'rxjs/operators';

@Injectable()

export class ProductsService {
    aProductsSubject = new Subject<Iproducts[]>();
    aProductsObs: Observable<Iproducts[]>;
    listImageUrls: string[] = [];
    listProductsImages = new Subject<string[]>();
    aProductsImgsObs: Observable<string[]>;

    constructor(private firestore: AngularFirestore, private storage: AngularFireStorage) {
        this.aProductsObs = this.aProductsSubject.asObservable();
        this.aProductsImgsObs = this.listProductsImages.asObservable();
        this.requestData();
        this.getAll();
    }

    requestData() {
        return this.firestore.collection<Iproducts>("products", ref => ref.orderBy('i_order', 'asc'))
        .snapshotChanges().subscribe(
            (res) => {
              const aProducts = res.map(e => {
                return  { key: e.payload.doc.id,
                  ...e.payload.doc.data() as Iproducts };
              });
              this.aProductsSubject.next(aProducts);
            }
        );
    }

    create(product: Iproducts){
        const subjectResult = new Subject<boolean>();
        this.firestore.collection('products').add(product)
            .then((res) => {
                this.requestData();
                subjectResult.next(true);
            }).catch((err) => {
                subjectResult.next(false);
            })
        return subjectResult.asObservable();
    }

    update(product: Iproducts) {
        const subjectResult = new Subject<boolean>();
        this.firestore.collection('products').doc(product.key).update(product)
            .then((res) => {
                this.requestData();
                subjectResult.next(true);
            }).catch(
                (err) => {
                    subjectResult.next(false);
                }
            )
        return subjectResult.asObservable();
    }

    delete(product: Iproducts) {
        const subjectResult = new Subject<boolean>();
        this.firestore.collection('products').doc(product.key).delete()
            .then((res) => {
                this.requestData();
                subjectResult.next(true);
            }).catch(
                (err) => {
                    subjectResult.next(false);
                }
            )
        return subjectResult.asObservable();
    }

    getAll() {
        const storageRef = this.storage.storage.ref();
        const listRef = storageRef.child('products');
        return listRef.listAll().then(
            (res) => {
                const obsUrls: Promise<any>[] = [];
                res.items.forEach((itemRef) => {
                // All the items under listRef.
                    obsUrls.push(itemRef.getDownloadURL()); 
                });
                if (obsUrls.length > 0) {
                    forkJoin(obsUrls).subscribe((urls) => {
                        this.listImageUrls = urls;
                        this.listProductsImages.next(urls);
                    })
                } else {
                    this.listImageUrls = [];
                    this.listProductsImages.next([]);
                }
            }).catch(function(error) {
                this.listImageUrls = [];
                this.listProductsImages.next([]);
                console.error("List of products not retrieved")
          });
        
    }

    saveFile(file: File) {
        var n = Date.now();
        const filePath = `products/${n}`;
        const fileRef = this.storage.ref(filePath);
        const task = this.storage.upload(`products/${n}`, file);
        task.snapshotChanges().pipe(
            finalize(() => {
              const downloadURLObs = fileRef.getDownloadURL();
              downloadURLObs.subscribe((url) => {
                if (url) {
                    this.listImageUrls.push(url);
                    this.listProductsImages.next(this.listImageUrls);
                }
              });
            })
          )
          .subscribe(url => {
            // do nothing
          });
    }
    

}