import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { AngularFirestore } from '@angular/fire/firestore';
import { Iprices } from 'app/models/prices';

@Injectable()

export class PricesService {
    aPricesSubject = new Subject<Iprices[]>();
    aPricesObs: Observable<Iprices[]>;

    constructor(private http: HttpClient, private firestore: AngularFirestore) {
        this.aPricesObs = this.aPricesSubject.asObservable();
        this.requestData();
    }

    requestData() {
        return this.firestore.collection<Iprices>("prices")
        .snapshotChanges().subscribe(
            (res) => {
              const aPrices = res.map(e => {
                return  { key: e.payload.doc.id,
                  ...e.payload.doc.data() as Iprices };
              });
              this.aPricesSubject.next(aPrices);
            }
        );
    }

    create(price: Iprices){
        const subjectResult = new Subject<boolean>();
        this.firestore.collection('prices').add(price)
            .then((res) => {
                this.requestData();
                subjectResult.next(true);
            }).catch(
                (err) => {
                    subjectResult.next(false);
                }
            )
        return subjectResult.asObservable();
    }

    update(price: Iprices) {
        const subjectResult = new Subject<boolean>();
        this.firestore.collection('prices').doc(price.key).update(price)
            .then((res) => {
                this.requestData();
                subjectResult.next(true);
            }).catch(
                (err) => {
                    subjectResult.next(false);
                }
            )
        return subjectResult.asObservable();
    }

    delete(price: Iprices) {
        const subjectResult = new Subject<boolean>();
        this.firestore.collection('prices').doc(price.key).delete()
            .then((res) => {
                this.requestData();
                subjectResult.next(true);
            }).catch(
                (err) => {
                    subjectResult.next(false);
                }
            )
        return subjectResult.asObservable();
    }

}