<mat-card>
    <mat-card-title>
        {{header}}
    </mat-card-title>
    <mat-card-content>
        <ng-content ></ng-content>
    </mat-card-content>
    <mat-card-actions>
        <button mat-button (click)='outputSave()' [disabled]=!touched>
            <mat-icon color="secondary">done</mat-icon>
            <div *ngIf='touched' class="text">
                Save
            </div>
            <div *ngIf='!touched' class="text">
                Saved
            </div>
        </button>
    </mat-card-actions>
</mat-card>