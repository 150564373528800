import { Component, OnInit, ViewChild, ElementRef, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { Inews } from 'app/models/news';
import { Idays } from 'app/models/days';
import { Iprices } from 'app/models/prices';
import { Iinfos } from 'app/models/infos';
import { Iproducts } from 'app/models/products';
import { NewsService } from 'app/services/news.service';
import { TimetableService } from 'app/services/timetable.service';
import { MainInfosService } from 'app/services/infos.service';
import { ProductsService } from 'app/services/products.service';
import { PricesService } from 'app/services/prices.service';
import { forkJoin, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  providers: [NewsService, TimetableService, MainInfosService, ProductsService, PricesService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class HomeComponent implements OnInit, OnDestroy  {
  @ViewChild('mapContainer') gmap: ElementRef;
  map: google.maps.Map;
  lat = 43.091299;
  lng = 1.929578;
  coordinates: google.maps.LatLng;
  mapOptions: google.maps.MapOptions;
  marker: google.maps.Marker;

  todayNum: string = null;
  isOpenNow: boolean = false;

  loaded = false;
  unsubscribe = new Subject();

  data: {
    notes?: Inews[],
    timetable?: Idays[],
    prices?: Iprices[],
    info?: Iinfos,
    products?: Map<string, Iproducts[]>
  };
 
  constructor(
    private cd: ChangeDetectorRef,
    public mainInfoService: MainInfosService,
    public newsService: NewsService,
    public timeTableService: TimetableService,
    public pricesService: PricesService,
    public productsService: ProductsService) {

      this.data = {};
      this.coordinates = new google.maps.LatLng(this.lat, this.lng);
      this.mapOptions = {
        center: this.coordinates,
        zoom: 8,
      };

      this.marker = new google.maps.Marker({
        position: this.coordinates,
        map: this.map,
      });

   }


  ngOnInit() {
    //this.a_products = new  Map<string, Iproducts[]>();
    forkJoin([
      this.mainInfoService.infoObs.pipe(take(1)),
      this.newsService.aNewsObs.pipe(take(1)),
      this.timeTableService.aDaysObs.pipe(take(1)),
      this.pricesService.aPricesObs.pipe(take(1)),
      this.productsService.aProductsObs.pipe(take(1))])
      .pipe(takeUntil(this.unsubscribe)).subscribe(
        ([infos, notes, timetable, prices, products]) => {
          if (infos !== null) {
            this.data.info = infos;
          } 
          if (notes !== null) {
            this.data.notes = notes;
          } 
          if (products !== null) {
            this.data.products = this.parseProductsIntoMap(products);
          }
          if (timetable !== null) {
            this.data.timetable = timetable;
            this.setOpenDay(timetable);
          }
          if (prices !== null) {
            this.data.prices = prices;
          }
          this.loaded = true;
          this.cd.markForCheck();
          setTimeout(() => {
            this.mapInitializer();
            this.marker.setMap(this.map);
          }, 500);
        }, 
        (error) => {
        }
      );
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  setOpenDay(timetable: Idays[]) {
    const d = new Date();
    this.todayNum = d.getDay().toString();
    
    for (const day of timetable) {
      if (day.s_id == this.todayNum) {
        this.isOpenNow = this.checkIfOpen(day, d);
      }
    }
  }

  checkIfOpen(day: Idays, d: Date) {
    const hour = d.getHours();
    if (day.b_open) {
      try {
        const dateOpenSplit = day.s_opening.split(':');
        const dateCloseSplit = day.s_closing.split(':');
        const hourOpen = parseInt(dateOpenSplit.shift(),10);
        const minOpen = parseInt(dateOpenSplit.shift(),10);
        const minClose = parseInt(dateCloseSplit.pop(),10);
        const hourClose = parseInt(dateCloseSplit.pop(),10);
        if (hourOpen < hour && hour < hourClose) {
          return true;
        } else {
          const min = d.getMinutes();
          if (hourOpen == hour ) {
            // compare min
            return min > minOpen;
          }
          if (hourClose == hour) {
            // compare min
            return min < minClose;
          }
          return false;
        }
      } catch (error) {
        return false;
      }
    }
  }

  private parseProductsIntoMap(data: Iproducts[]) {
    const mapProducts = new Map<string, Iproducts[]>();
    for (const product of data) {
      if (!product.b_show) {
        continue;
      }
      if (mapProducts.has(product.a_type[0])) {
        mapProducts.get(product.a_type[0]).push(product);
      } else {
        const new_array = new Array();
        new_array.push(product);
        mapProducts.set(product.a_type[0], new_array);
      }
    }
    return mapProducts;
  }


  /** This function order the produit map by key (alphabetically, 
   * keeping the apples as first and the other fruits as second) */
  public orderProduitsMap = (a, b) => {
    if (a.key === "pommes") { 
      return -1;
    } else if (b.key === "pommes") {
      return 1;
    }
    if (a.key === "fruits") { 
      return -1;
    } else if (b.key === "fruits") {
      return 1;
    } else {
      return b - a;
    }
      
  }

  public mapInitializer() {
    this.map = new google.maps.Map(this.gmap.nativeElement, 
    this.mapOptions);
  }

}