import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-intro-video',
  templateUrl: './intro-video.component.html',
  styleUrls: ['./intro-video.component.scss']
})
export class IntroVideoComponent implements OnInit {
  @Output() closeVideo: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    private router: Router
  ) { }

  ngOnInit(): void {
  }

  setVideoSettings(event: EventEmitter<boolean>) {
    console.log('event ', event)
    localStorage.setItem('skipVideo', JSON.stringify(event));
  }

  exitVideo() {
    console.log('emited event')
    this.closeVideo.emit();
  }

}
