import { Component, OnInit, OnDestroy, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { GalleryService } from 'app/services/gallery.service';
import { takeUntil } from 'rxjs/operators';
import { Iimage } from 'app/models/gallery';
import { Subject } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { trigger, state, style, transition, animate } from '@angular/animations';

@Component({
  selector: 'app-photos',
  templateUrl: './photos.component.html',
  styleUrls: ['./photos.component.scss'],
  providers: [GalleryService],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: 
  [
    trigger(
      'showBigImageAnimation',
      [
        transition(
        ':enter', [
          style({transform: 'translateX(-100%)', opacity: 0}),
          animate('500ms', style({transform: 'translateX(0)', 'opacity': 1}))
        ]
      ),
      transition(
        ':leave', [
          style({transform: 'translateX(0)', 'opacity': 1}),
          animate('500ms', style({transform: 'translateX(100%)', 'opacity': 0})),
        ]
      )]
    ), 
    
  ],
})
export class PhotosComponent implements OnInit, OnDestroy {
  bigImage: Iimage = null;
  ready = false;
  aImages: Iimage[] = [];
  unsubscribe = new Subject();
  index: number = 0;
  
  constructor(
    public galleryService: GalleryService,
    private cd: ChangeDetectorRef,
    private _snackBar: MatSnackBar ) { }

  ngOnInit() {
    this.galleryService.aGalleryObs
    .pipe(takeUntil(this.unsubscribe))
    .subscribe(
      (gallery) => {
        
        this.aImages = gallery.filter(image => image.visible);
        if (gallery.length === 0) {
          this._snackBar.open("Une erreur est survenue. Veuillez réessayer plus tard.", 'Ok', {
            duration: 1500});
        }
        this.ready = true;
        this.cd.markForCheck();
      }
    )
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public selectPhoto(photo: Iimage) {
    this.bigImage = photo;
  }

  public closeImg() {
    this.bigImage = null;
  }

}
