import { Component, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Iproducts } from 'app/models/products';

@Component({
    selector: 'new-product-dialog',
    templateUrl: 'new-product.component.html',
    styleUrls: ['new-product.component.scss'],
  })
  export class NewProductDialog {

    productForm = new FormGroup({
      name: new FormControl('', Validators.required),
      description: new FormControl(''),
      type: new FormControl('', Validators.required),
      show: new FormControl(false),
      visible: new FormControl(false),
    });

    totalProducts = 1;
    types: string[] = [];
  
    constructor(
      public dialogRef: MatDialogRef<NewProductDialog>,
      @Inject(MAT_DIALOG_DATA) public data: {
        totalProducts: number,
        types: string[],
      }) {
        this.totalProducts = data.totalProducts;
        this.types = data.types;
    }

    get show() {
      return this.productForm.get('show').value;
    }

    get visible() {
      return this.productForm.get('visible').value;
    }
  
    onNoClick(): void {
      this.dialogRef.close(null);
    }

    submit() {
      const newProduct: Iproducts = {
        s_name: this.productForm.get('name').value,
        s_info: this.productForm.get('description').value,
        a_type: this.productForm.get('type').value,
        b_disponible: this.productForm.get('visible').value,
        b_show: this.productForm.get('show').value,
        i_order: this.totalProducts + 1,
        s_img: ''
      }
      this.dialogRef.close(newProduct);
    }
  
  }