import { Component, OnInit } from '@angular/core';
import { MainInfosService } from 'app/services/infos.service';
import { DataService } from 'app/services/data.service';
import { IMessage } from 'app/models/message';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
  providers: [MainInfosService, DataService]
})
export class ContactComponent implements OnInit {
  private unsubscribe = new Subject<void>();
  form: FormGroup;
  
  constructor(
    public mainInfoService: MainInfosService,
    private dataService: DataService,
    private _snackBar: MatSnackBar,
    private fb: FormBuilder) { }

  ngOnInit() {
    this.form = new FormGroup({
      "email" :new FormControl("", [Validators.required, Validators.email]),
      "name": new FormControl("", Validators.required),
      "phone":new FormControl("", Validators.required),
      "message":new FormControl("", Validators.required),
      "subject":new FormControl("", Validators.required),
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public onSubmit() {
    const message: IMessage = {
      subject: this.form.get('subject').value,
      name: this.form.get('name').value,
      email: this.form.get('email').value,
      message: this.form.get('message').value,
      telephone: this.form.get('phone').value,
      timestamp: new Date(),
      view: false,
    }
    this.dataService.create(message)
    .pipe(takeUntil(this.unsubscribe))
    .subscribe(
      (res) => {
        this.form.reset();
        this._snackBar.open("Votre message a été envoyé avec succès", 'Ok', {
          duration: 1500});
        this.sendEmail(message);
      }, 
      (error) => {
        this._snackBar.open(
          "Il y a eu une erreur, s'il vous plait, merci d'essayer de nous contacter par téléphone.", 
          'Ok', 
          { duration: 1500});
        console.log('email Error', error);
      }
    );
  }

  private sendEmail(message) {
    this.dataService.sendEmail(message)
    .pipe(takeUntil(this.unsubscribe))
    .subscribe(
      (res) => {
        console.log('email Success', res);
      }, 
      (error) => {
        console.log('email Error', error);
      });
  }

}
