import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { Iimage } from 'app/models/gallery';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { finalize } from 'rxjs/operators';

@Injectable()

export class GalleryService {
    aGallerySubject = new Subject<Iimage[]>();
    aGalleryObs: Observable<Iimage[]>;

    constructor(private firestore: AngularFirestore, private storage: AngularFireStorage) {
        this.aGalleryObs = this.aGallerySubject.asObservable();
        this.requestData();
    }

    requestData() {
        return this.firestore.collection<Iimage>("gallery")
        .snapshotChanges().subscribe(
            (res) => {
              const aGallery = res.map(e => {
                return  { key: e.payload.doc.id,
                  ...e.payload.doc.data() as Iimage };
              });
              this.aGallerySubject.next(aGallery);
            }
        );
    }

    create(image: Iimage){
        const subjectResult = new Subject<boolean>();
        this.firestore.collection('gallery').add(image)
            .then((res) => {
                this.requestData();
                subjectResult.next(true);
            }).catch(
                (err) => {
                    subjectResult.next(false);
                }
            )
        return subjectResult.asObservable();
    }

    
    update(note: Iimage) {
        const subjectResult = new Subject<boolean>();
        this.firestore.collection('gallery').doc(note.key).update(note)
            .then((res) => {
                this.requestData();
                subjectResult.next(true);
            }).catch(
                (err) => {
                    subjectResult.next(false);
                }
            )
        return subjectResult.asObservable();
    }

    delete(note: Iimage) {
        const subjectResult = new Subject<boolean>();
        this.firestore.collection('gallery').doc(note.key).delete()
            .then((res) => {
                this.requestData();
                subjectResult.next(true);
            }).catch(
                (err) => {
                    subjectResult.next(false);
                }
            )
        return subjectResult.asObservable();
    }

    saveFile(file: File) {
        var n = Date.now();
        const filePath = `gallery/${n}`;
        const fileRef = this.storage.ref(filePath);
        const task = this.storage.upload(`gallery/${n}`, file);
        task.snapshotChanges().pipe(
            finalize(() => {
              const downloadURLObs = fileRef.getDownloadURL();
              downloadURLObs.subscribe(url => {
                if (url) {
                    this.create({url: url, visible: true, filename: file.name});
                }
              });
            })
          )
          .subscribe(url => {
            // do nothing
          });
    }
    
}