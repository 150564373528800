import { Component, OnInit, OnDestroy, ChangeDetectorRef, ChangeDetectionStrategy, HostListener } from '@angular/core';
import { AuthService } from './services/auth.service';
import { fromEvent, Observable, Subscription } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'bedou2';
  public skipVideo = true;
  private userSub: Subscription;
  public isAuthenticated = false;
  screenHeight: number;
  screenWidth: number;

  constructor(
    private authService: AuthService, 
    private cd: ChangeDetectorRef,
    private router: Router) {

  }

  ngOnInit(): void {
    this.getScreenSize();
    if (this.screenWidth < 768) { // 768px portrait
      this.skipVideo = true;
    } else {
      const value = localStorage.getItem('skipVideo');
      this.skipVideo = value === 'true';
    }
    this.userSub = this.authService.user.subscribe(
      user => {
        this.isAuthenticated = !user ? false : true;
        this.cd.markForCheck();
      }
    )
  }

  @HostListener('window:resize', ['$event'])
    getScreenSize(event?) {
          this.screenHeight = window.innerHeight;
          this.screenWidth = window.innerWidth;
          if (this.screenWidth < 768) {
            this.skipVideo = true;
          }
          console.log(this.screenHeight, this.screenWidth);
    }

  ngOnDestroy(): void {
    this.userSub.unsubscribe();
  }

  goToAdminPage() {
    this.router.navigate(['admin']);
  }

  logout(){
    this.authService.logout();
  }

  closeVideo() {
    console.log('close video')
    this.skipVideo = true;
  }

}
