<div fxFlex fxLayout="column">
<div fxFlex fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-around start"
    fxLayoutAlign.xs="center stretch">
    <!-- NEWS  -------------------------->
    <app-modif-card [header]="'News'" [touched]='touchedNew' (actionSave)='saveNew()'>
        <div *ngFor='let new of newsService.aNewsObs | async' fxLayout="row" fxLayoutAlign="start center">
            <div fxFlex class="cm-line-pad">
                > {{new.s_description}}
            </div>
            <div (click)='changeVisibility(new)' fxFlex='20' class="icon-btn">
                <div *ngIf='new.b_show; else hidden'>
                    <mat-icon class="icon">visibility</mat-icon>
                </div><ng-template #hidden >
                    <mat-icon class="icon">visibility_off</mat-icon>
                </ng-template>
            </div>
            <mat-icon color='warn' fxFlex='20' class="icon-btn" (click)='deleteNew(new)'>clear
            </mat-icon>
        </div>
        <div fxLayout="row" fxLayoutAlign="start center">
            <mat-form-field fxFlex>
                <textarea matInput cdkTextareaAutosize class="modif-input" ngModel name='newNew'
                    placeholder="Set here your new line" [(ngModel)]="newNewDesc" (input)='inputNew()'>
                </textarea>
            </mat-form-field>
        </div>
    </app-modif-card>
    <!-- END NEWS  -------------------------->
    <!-- PRICES  -------------------------->
    <app-modif-card [header]="'Prices'" [touched]='touchedPrice' (actionSave)='savePrices($event)'>
        <div class="container-lines">
            <div *ngFor='let price of pricesService.aPricesObs | async' fxLayout="row" fxLayoutAlign="start center">
                <div class="container-lines">
                    <p class="subtitle">
                        {{price.s_title}}:
                        <mat-icon class="mat-18" (click)="editPrice(price)" class="icon-edit">edit</mat-icon>
                    </p>
                    <div *ngIf="priceInEdition(price); else displayPrice" class="container-lines">
                        <div *ngFor="let newType of newPrice.types" class="line-inside">
                            <mat-form-field class="mr-1">
                                <input matInput placeholder="variete" [(ngModel)]="newType.s_type_title">
                            </mat-form-field>
                            <mat-form-field class="mr-1">
                                <input matInput placeholder="price per kg" [(ngModel)]="newType.s_price">
                            </mat-form-field>
                        </div>
                    </div>
                    <ng-template #displayPrice>
                        <div *ngFor="let type of price.types" class="line-inside">
                            {{type.s_type_title}} -> {{type.s_price}}
                        </div>
                    </ng-template>
                </div>
            </div>
        </div>
    </app-modif-card>
</div>
<div fxFlex fxLayoutAlign="space-around start" fxLayoutAlign.xs="center stretch">
    <!-- END PRICES  -------------------------->
    <!-- TIMETABLE  -------------------------->
    <app-modif-card [header]="'Horaire'" [touched]='touchedTimetable' (actionSave)='saveTimetable($event)'>
        <div class="flex-wrap-container">
            <div *ngFor='let day of timetableService.aDaysObs | async' class="timetable-column">
                <div class="container-lines">
                    <p class="subtitle">
                        {{day.s_name}}:
                        <mat-icon (click)="editDay(day)" class="icon-edit">edit</mat-icon>
                    </p>
                    <div *ngIf="dayInEdition(day); else displayDay" class="line-inside container-lines">
                        <mat-slide-toggle [(ngModel)]="newDay.b_open">{{newDay.b_open ? 'Ouvert' : 'Ferme'}}</mat-slide-toggle>
                        <div *ngIf="newDay.b_open">
                            <mat-form-field class="mr-1">
                                <input matInput placeholder="Ouverture" [(ngModel)]="newDay.s_opening">
                            </mat-form-field>
                            <mat-form-field class="mr-1">
                                <input matInput placeholder="Fermeture" [(ngModel)]="newDay.s_closing">
                            </mat-form-field>
                        </div>
                    </div>
                    <ng-template #displayDay>
                        <div class="line-inside">
                            <span class="mr-2">{{day.b_open ? 'Ouvert' : 'Fermé'}}</span>
                            <div *ngIf="day.b_open">
                                {{day.s_opening}} -> {{day.s_closing}}
                            </div>
                        </div>
                    </ng-template>
                </div>
            </div>
        </div>
    </app-modif-card>
    <!-- END TIMETABLE  -------------------------->
</div>
</div>