<mat-card>
    <mat-card-header>
        <mat-card-title>Photos</mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <div class="flex-wrap-container">
            <div *ngFor="let imageGallery of galleryService.aGalleryObs | async" class="gallery-column">
                <img [src]="imageGallery.url" alt="" class="img-gallery">
                <div class="img-actions">
                    <div *ngIf="imgInEdition(imageGallery); else displayImg" class="img-edit">
                        <mat-slide-toggle [(ngModel)]="imageGallery.visible">{{imageGallery.visible ? 'Visible' : 'Caché'}}</mat-slide-toggle>
                        <mat-icon class="icon-neutral" (click)='deleteImg(imageGallery)'>delete</mat-icon>
                        <mat-icon class="icon-neutral" (click)='saveImg(imageGallery)'>save</mat-icon>
                    </div>
                    <ng-template #displayImg>
                        <div class="img-display">
                            <span>{{imageGallery.filename}}</span>
                            <div class="icons">
                                <mat-icon class="icon-neutral">{{imageGallery.visible ? 'visibility' : 'visibility_off'}}</mat-icon>
                                <mat-icon class="icon-neutral" (click)="editImg(imageGallery)" class="icon-edit">edit</mat-icon>
                            </div>
                        </div>
                    </ng-template>
                </div>  
            </div>
        </div>
    </mat-card-content>
    <mat-card-actions>
        <div class="newImage">
            <label for="file">Ajouter une nouvelle image: </label>
            <input type="file"
                id="file"
                #newImgGallery
                (change)="onFileSelected($event)"
                name="image"
                autocomplete="off"
            />
        </div> 
    </mat-card-actions>
</mat-card>