import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { IMessage } from 'app/models/message';
import { DataService } from 'app/services/data.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-inbox',
  templateUrl: './inbox.component.html',
  styleUrls: ['./inbox.component.scss'],
  providers: [
    DataService
  ]
})
export class MessagesComponent implements OnInit, OnDestroy {
  private unsubscribe = new Subject<void>();

  messages: IMessage[] = [];

  constructor(
    private _snackBar: MatSnackBar,
    public messagesService: DataService) { }

  ngOnInit() {
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  markAsRead(msg: IMessage) {
    if (msg != null) {
      const updateFields: IMessage = {
        key: msg.key,
        view: !msg.view,
      }
      this.messagesService.update(updateFields)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        () => {
          this._snackBar.open("Bien sauvergardé", 'Ok', {
            duration: 1500});
        });
    }
  }

  answerMsg(msg: IMessage) {
    const mailText = `mailto:${msg.email}&body=''`; // add the links to body
    window.location.href = mailText;
  }

  deleteMsg(msg: IMessage) {
    if (msg != null) {
      this.messagesService.delete(msg)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        () => {
          this._snackBar.open("Bien effacé", 'Ok', {
            duration: 1500});
        });
    }
  }
}
