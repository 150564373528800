
<div fxLayout="row" fxLayout.lt-sm="column"
    *ngIf="loaded; else loading">
    <!-- TODO: put it in a forkjoin in the component -->
    <div class="when">
        <mat-card *ngIf="data?.notes" class="news-card">
            <h4>Notes</h4>
            <div *ngFor="let note of data?.notes" >
                <mat-card-content *ngIf='note?.b_show'>
                    {{note?.s_description}}
                </mat-card-content>
            </div>
        </mat-card>
        <mat-card>
            <mat-card-title>
                Horaire
            </mat-card-title>
            <mat-card-content>
                <div *ngFor="let day of data?.timetable" 
                    [ngClass]="{'date': true, 'todayDate': todayNum == day.s_id, 'open': (todayNum == day.s_id && isOpenNow)}">
                    <span class="day">{{day?.s_name}}:</span>
                    <span *ngIf="day?.b_open; else closed">
                        Ouvert du {{day?.s_opening}} - {{day?.s_closing}}
                    </span>
                    <ng-template #closed>
                        Fermé
                    </ng-template>
                </div>
            </mat-card-content>
        </mat-card>
        <mat-card>
            <mat-card-title>
                Prix
            </mat-card-title>
            <mat-card-content *ngFor="let oPrice of data?.prices">
                <b>{{oPrice?.s_title | titlecase}}</b>
                <div *ngFor='let catPrice of oPrice?.types' class="prices">
                    <b class="left-side">{{catPrice?.s_type_title}}</b><span class="right-side">{{catPrice?.s_price}}</span>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="what" >
        <mat-card>
            <mat-card-content *ngFor="let o_line of data?.info?.description">
                {{o_line}}
            </mat-card-content>
        </mat-card>
        <mat-card>
            <mat-card-content *ngFor="let o_product_list of data?.products | keyvalue: orderProduitsMap">
                <ul class="product-row">
                    <span class="product-title">{{o_product_list.key | productTypeLbl}}:</span>
                    <li *ngFor="let o_product of o_product_list.value" class="product-column">
                        {{o_product.s_name}}
                    </li>
                </ul>

            </mat-card-content>
        </mat-card>
    </div>
    <div class="where" >
        <mat-card>
            <mat-card-header>
                <mat-card-title>Téléphone</mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <div *ngFor="let tel of data?.info?.telephone" class="telephone">
                    <mat-icon class="mat-18">phone</mat-icon>
                    <a href="tel:{{tel}}">{{tel}}
                    </a>
                </div>
            </mat-card-content>
        </mat-card>
        <mat-card>
            <mat-card-header>
                <mat-card-title>Addresse</mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <div *ngFor="let line of data?.info?.address" class="address">
                    <span>{{line}}</span>
                </div>
                <div #mapContainer id="map"></div>
            </mat-card-content>
        </mat-card>
    </div>
</div>
<ng-template #loading >
    <div class="center-content loading">
        <mat-spinner></mat-spinner>
    </div>
</ng-template>