<div class="contact-cards-container">
    
    <div class="card-container">
        <mat-card>
            <mat-card-header>
                <mat-card-title>Téléphone</mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <div *ngIf="(mainInfoService.infoObs | async) as mainInfo">
                    <div *ngFor="let tel of mainInfo?.telephone" class="telephone">
                        <mat-icon class="mat-18">phone</mat-icon>
                        <a href="tel:+33{{tel}}" class="number">{{tel}}</a>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="card-container">
        <mat-card class="full-width">
            <form [formGroup]="form" (ngSubmit)='onSubmit()' class="main-form">
            <mat-card-header>
                <mat-card-title>Nous contacter</mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <div class="form-body">
                   
                    <div class="user-info-container">
                        <mat-form-field>
                            <input mat-input type="text" placeholder="Votre
                            nom" matInput id="name" formControlName="name" required />
                        </mat-form-field>
                        <mat-form-field>
                            <input mat-input type="text" placeholder="Votre
                            email" matInput id="email" formControlName="email"
                            pattern="[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}" 
                            required />
                        </mat-form-field>
                        <mat-form-field>
                            <input mat-input type="text" placeholder="Votre
                            phone" matInput id="phone" formControlName="phone"
                            required />
                        </mat-form-field>
                    </div>
                    <mat-form-field class="example-full-width">
                        <mat-label>Sujet</mat-label>
                        <input matInput maxlength="128" placeholder="" formControlName="subject">
                    </mat-form-field>
                    <mat-form-field class="example-full-width">
                        <mat-label>Message</mat-label>
                        <input matInput #message maxlength="512" placeholder="" formControlName="message">
                        <mat-hint align="end">{{message.value.length}} / 512</mat-hint>
                    </mat-form-field>
                </div>
            </mat-card-content>
            <mat-card-actions align="end">
                <button mat-raised-button type='submit' [disabled]="!form.valid" color="primary">
                    Envoyer
                </button>

            </mat-card-actions>
            </form>
        </mat-card>
    </div>
    <div class="card-container">
        <mat-card>
            <mat-card-content>
                <p class="custom-title">Facebook</p>
                <div class="line-wth-icons">
                    <mat-icon class="mat-18">face</mat-icon>
                    <mat-icon class="mat-18">menu_book</mat-icon>
                    <a href="https://www.facebook.com/LesVergersDuBedou/" target="_blank" rel="noopener noreferrer">Les vergers du Bedou</a>
                </div>
                <p class="custom-title">Email</p>
                <div class="line-wth-icons">
                <mat-icon class="mat-18">alternate_email</mat-icon>
                <a href="mailto:lesvergersdubedou@gmail.com" target="_blank" rel="noopener noreferrer">lesvergersdubedou @ gmail . com</a>
            </div>
            </mat-card-content>
        </mat-card>
    </div>
</div>