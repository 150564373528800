import { Component, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { ProductsService } from 'app/services/products.service';
import { Iproducts } from 'app/models/products';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss'],
  providers: [ProductsService]
})
export class ProductsComponent implements OnInit, OnDestroy {
  filteredItems: Iproducts[] = [];
  items: Iproducts[] = [];
  types: string[] = [];
  public ready = false;
  public search = "";
  public disponibleOnly = false;
  public selectedType = null;
  unsubscribe = new Subject();

  constructor(private dataService: ProductsService, private cd: ChangeDetectorRef) { }

  ngOnInit() {
    this.dataService.aProductsObs.pipe(takeUntil(this.unsubscribe))
    .subscribe((products) => {
      this.items = products.filter(
        (item: Iproducts) => item.b_show
      );
      for (const product of products) {
        for (const type of product.a_type) {
          if (!this.types.includes(type)) {
            this.types.push(type);
          }
        }
      }
      this.ready = true;
      this.assignCopy();
      
    })
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public assignCopy(){
    this.filteredItems = Object.assign([], this.items);
    this.cd.markForCheck();
 }

 public onSearchInput(value: string) {
  this.search = value;
  this.filter();
}

  public filterItem(items: Iproducts[]) {
    if(this.search != null){
     return Object.assign([], items).filter(
        item => item.s_name.toLowerCase().indexOf(this.search.toLowerCase()) > -1
      )
    } // when nothing has typed
    
  }

  public onTypeSelected(type: string) {
    this.selectedType = type;
    this.filter();
  }

  public filterByType(items: Iproducts[]) {
    return Object.assign([], items).filter(
      item => item.a_type.includes(this.selectedType)
    )
  }

  public onDisponbleChanged(event: any) {
    this.disponibleOnly = event.checked;
    this.filter();
  }

  public filterByDisponibility(items: Iproducts[]) {
    if (this.disponibleOnly) {
      return Object.assign([], items).filter(
        (item: Iproducts) => {
          return item.b_disponible ===  this.disponibleOnly;
        } 
      )
    } else {
      return Object.assign([], this.items);
    }
  }

  public filter() {
    let newItems = Object.assign([], this.items);
    if (this.disponibleOnly) {
      newItems = this.filterByDisponibility(newItems);
    }
    if (this.selectedType != null) {
      newItems = this.filterByType(newItems);
    }
    if (this.search != null) {
      newItems = this.filterItem(newItems);
    }
    this.filteredItems = newItems;
  }

}

