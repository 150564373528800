<h2>Messages</h2>
<div  class="messages-container">

    <mat-accordion multi class="full-width">
        <mat-expansion-panel  *ngFor="let msg of messagesService.aMessagesObs | async"
            [ngClass]="{'message-read': msg.view, 'message-unread': !msg.view}" class="message">
            <mat-expansion-panel-header>
                <mat-panel-title class="msg-from">
                    <div class="item">
                        <label for="from">From: </label>
                        <p id="from">{{msg.name}}</p>
                    </div>
                </mat-panel-title>
                <mat-panel-description>
                    <div class="msg-subject">
                        {{msg.subject}}
                    </div>
                    <div class="msg-desc">
                        {{msg.timestamp | firestoreDate}}
                    </div>
                </mat-panel-description>
            </mat-expansion-panel-header>
            <div class="msg-infos">
                <div class="item">
                    <label for="email">Email: </label>
                    <p id="email">{{msg.email}}</p>
                </div>
                <div class="item">
                    <label for="telephone">Telephone: </label>
                    <a href="tel:{{msg.telephone}}" class="number">{{msg.telephone}}</a>
                </div>
            </div>
            <div class="item">
                <p id="message">{{msg.message}}</p>
            </div>
            <div class="actions">
                <button mat-button (click)="markAsRead(msg)">
                    <mat-icon class="icon-neutral">{{msg.view ? 'mark_email_unread' : 'mark_email_read'}}</mat-icon>
                                {{msg.view ? 'Marque comme non lu' :  'Marque comme lu'}}
                </button>
                <button mat-button (click)="answerMsg(msg)">
                    <mat-icon class="icon-neutral">forward_to_inbox</mat-icon>
                    Répondre
                </button>
                <button mat-button (click)="deleteMsg(msg)">
                    <mat-icon class="icon-neutral">delete</mat-icon>
                    Effacer
                </button>
            </div>
        </mat-expansion-panel>
    </mat-accordion>
</div>