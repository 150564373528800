<form [formGroup]='productForm' (ngSubmit)="submit()">
  <div mat-dialog-content class="form-content"> 
    <mat-form-field class="item">
      <input  matInput placeholder="Nom du produit" formControlName="name"/>
    </mat-form-field>
    <mat-form-field class="item">
      <textarea  matInput placeholder="Description" formControlName="description"></textarea>
    </mat-form-field>
    <mat-form-field class="item" appearance="fill">
      <mat-label>Categories</mat-label>
      <mat-select formControlName="type"  multiple>
        <mat-option *ngFor="let type of types" [value]="type">{{type | productTypeLbl}}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-slide-toggle class='item' labelPosition="before" formControlName="show">{{show ? 'Disponible' : 'Epuisé '}}</mat-slide-toggle>
    <mat-slide-toggle class='item' labelPosition="before" formControlName="visible">{{visible ? 'Visible' : 'Caché'}}</mat-slide-toggle>
    <p>L'image doit être sélectionnée après la création du produit.</p>
  </div>
  <div mat-dialog-actions class="dialog-btns">
    <button mat-button (click)="onNoClick()">Cancel</button>
    <button mat-button  cdkFocusInitial [disabled]='!productForm.valid'>Ok</button>
  </div>
</form>