<div>
    <div class="infos">
        <ul class="text">
            <li>Notre principale activité est la production de pommes. Nous travaillons notre verger de façon raisonnée.</li>
            <li>Ces pommes sont produites avec des méthodes alternatives (confusion sexuelle, désherbage mécanique, préservation de la faune auxiliaire, utilisation autant que possible de produits homologués bio...) afin de réduire au maximum les interventions phytosanitaires.</li>
            <li>Nous avons aussi des productions labellisées bio : poires, pommes et jus de fruit.</li>
            <li>Nous produisons également divers légumes de plein champ : poireaux, poivrons, courgettes, potimarrons etc.</li>
        </ul>
        <div class="img-method">
            <img src="/assets/img/method.jpg" alt="">
        </div>
    </div>
    <div class="videos">
        <div>
            <iframe 
			src="https://www.youtube.com/embed/bFSacx6se3Y?wmode=transparent">
			</iframe>
        </div>
        <div>
            <iframe width="350" height="262"
			src="https://www.youtube.com/embed/mW5T41RMFLE?wmode=opaque">
			</iframe>
        </div>
    </div>
</div>