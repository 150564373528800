import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Iimage } from 'app/models/gallery';
import { GalleryService } from 'app/services/gallery.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-edit-photos',
  templateUrl: './edit-photos.component.html',
  styleUrls: ['./edit-photos.component.scss', './../modify.component.scss'],
  providers: [
    GalleryService
  ]
})
export class EditPhotosComponent implements OnDestroy  {
  private unsubscribe = new Subject<void>();

  touchedImg: boolean = false;
  newImage: Iimage = null;

  constructor(
    private _snackBar: MatSnackBar, 
    public galleryService: GalleryService,
  ) { }


  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }


  editImg(image: Iimage) {
    if(this.touchedImg) {
      this.touchedImg = false;
      this.newImage = null;
    } else {
      this.newImage = image;
      this.touchedImg = true;
    }
  }

  imgInEdition(image: Iimage) {
    return (this.newImage != null
      && this.newImage.key === image.key);
  }

  saveImg(image: Iimage) {
    if (image != null) {
      this.galleryService.update(image)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        () => {
          this.newImage = null;
          this.touchedImg = false;
          this._snackBar.open("Bien sauvergardé", 'Ok', {
            duration: 1500});
        });
    }
  }

  deleteImg(image: Iimage) {
    if (image != null) {
      this.galleryService.delete(image)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        () => {
          this.newImage = null;
          this.touchedImg = false;
          this._snackBar.open("Bien effacé", 'Ok', {
            duration: 1500});
        });
    }
  }

  onFileSelected(event) {
    var n = Date.now();
    const file = event.target.files[0];
    this.galleryService.saveFile(file);
  }

}
