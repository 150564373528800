<mat-tab-group class="custom-tabs">
    <mat-tab label="Accueil" class="tab">
        <app-edit-main-infos></app-edit-main-infos>
    </mat-tab>
    <mat-tab label="Produits" class="tab">
        <app-edit-products></app-edit-products>
    </mat-tab>
    <mat-tab label="Messages" class="tab">
        <app-inbox></app-inbox>
    </mat-tab>
    <mat-tab label="Photos"  class="tab">
        <app-edit-photos></app-edit-photos>
    </mat-tab>
</mat-tab-group>

