<mat-sidenav-container class="all-wrap">
  <mat-sidenav #sidenav position="end">
    <mat-nav-list>
      <a mat-list-item [routerLink]="'/'">
        <mat-icon class="icon-sidenav">home</mat-icon>
        <span class="label">Accueil</span>
      </a>
      <a mat-list-item [routerLink]="'/products'" (click)="sidenav.toggle()">
        <mat-icon class="icon-sidenav">shopping_cart</mat-icon>
        <span class="label">Produits</span>
      </a>
      <a mat-list-item [routerLink]="'/method'" (click)="sidenav.toggle()">
        <mat-icon class="icon-sidenav">brightness_low</mat-icon>
        <span class="label">Méthode culturale</span>
      </a>
      <a mat-list-item [routerLink]="'/photos'" (click)="sidenav.toggle()">
        <mat-icon class="icon-sidenav">camera_alt</mat-icon>
        <span class="label">Photos</span>
      </a>
      <a mat-list-item [routerLink]="'/contact'" (click)="sidenav.toggle()">
        <mat-icon class="icon-sidenav">contact_support</mat-icon>
        <span class="label">Nous contacter</span>
      </a>
      <mat-list-item (click)="sidenav.toggle()">
        <mat-icon class="icon-sidenav">close</mat-icon>
        <span class="label">Fermer</span>
      </mat-list-item>
    </mat-nav-list>
  </mat-sidenav>

  <mat-sidenav-content class="page-wrap page-background">
    <mat-toolbar color="primary">
      <mat-toolbar-row fxLayoutAlign="space-between" fxLayoutGap="20px">
        <div class="logo" fxFlexAlign="center" fxLayout="row">
          <img class="main-img" src="/assets/img/logo.png" alt="" />
          <h1 class="main-text">Les vergers du Bedou</h1>
        </div>
        <!-- Hiding the menu if resolution is lower than middle -->
        <div
          fxShow="true"
          fxHide.lt-md="true"
          class="menu"
          fxFlexAlign="center"
        >
          <a mat-button [routerLink]="'/'">
            <mat-icon class="icon">home</mat-icon>
            <span class="label">Accueil</span>
          </a>
          <a mat-button [routerLink]="'/products'">
            <mat-icon class="icon">shopping_cart</mat-icon>
            <span class="label">Produits</span>
          </a>
          <a mat-button [routerLink]="'/method'">
            <mat-icon class="icon">brightness_low</mat-icon>
            <span class="label">Méthode culturale</span>
          </a>
          <a mat-button [routerLink]="'/photos'">
            <mat-icon class="icon">camera_alt</mat-icon>
            <span class="label">Photos</span>
          </a>
          <a mat-button [routerLink]="'/contact'">
            <mat-icon class="icon">contact_support</mat-icon>
            <span class="label">Nous contacter</span>
          </a>
        </div>
        <div fxFlexAlign="center" fxHide.gt-md="true">
          <button mat-icon-button>
            <mat-icon (click)="sidenav.toggle()">menu</mat-icon>
          </button>
        </div>
      </mat-toolbar-row>
    </mat-toolbar>

    <!-- <main class="content main-content">
      <div class="content-width" *ngIf="skipVideo; else showVideo">
        <router-outlet></router-outlet>
      </div>
      <ng-template #showVideo>
        <app-intro-video (closeVideo)="closeVideo()"></app-intro-video>
      </ng-template>
    </main> -->
    <main class="content main-content">
      <div class="content-width">
        <router-outlet></router-outlet>
      </div>
    </main>
    <footer class="footer">
      <div class="footer-items" fxLayout="row">
        <div>
          <img
            class="footer-cert"
            (click)="goToAdminPage()"
            src="/assets/img/certifications.png"
            alt=""
          />
        </div>
        <div *ngIf="isAuthenticated" class="admin-container">
          <div [routerLink]="'/admin'" class="admin-items">Administation</div>
          <div (click)="logout()" class="admin-items">Logout</div>
        </div>
        <div fxFlex class="copyrigth">&copy;2020 Les Vergers du Bedou</div>
      </div>
    </footer>
  </mat-sidenav-content>
</mat-sidenav-container>
